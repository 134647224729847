
  .input-error {
    color: #ff0000;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 600;
  }

.form-gr {
  padding: 16px 0;

  label {
    display: block;
    font-weight: 600;
    color: #4a4949;
  }

  .form--control {
    width: 100%;
    border: none;
    box-shadow: 0px 25px 30px #0000001a;
    border-radius: 10px;
    padding: 16px;
  }

  .login-btn {
    width: 100%;
    background: #d4831a 0% 0% no-repeat padding-box;
    box-shadow: 0px 25px 30px #0000001a;
    padding: 12px;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    border: none;
    outline: none;
    transition: all 0.15s linear;

    &:hover {
      background: #ba751b 0% 0% no-repeat padding-box;
    }

    &:disabled {
      background: #ba751b 0% 0% no-repeat padding-box;
      opacity: 0.6;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.edit-button {
  background: #d4831a 0% 0% no-repeat padding-box;
  box-shadow: 0px 25px 30px #0000001a;
  padding: 8px;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  border: none;
  outline: none;
  transition: all 0.15s linear;
  margin: 0 10px 10px 10px;

  &:hover {
    background: #ba751b 0% 0% no-repeat padding-box;
  }

  &:disabled {
    background: #ba751b 0% 0% no-repeat padding-box;
    opacity: 0.6;
  }
}

.delete-button {
  background: #db4437 0% 0% no-repeat padding-box;
  box-shadow: 0px 25px 30px #0000001a;
  padding: 8px;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  border: none;
  outline: none;
  transition: all 0.15s linear;
  margin: 0 10px 10px 10px;

  &:hover {
    background: #b3382c 0% 0% no-repeat padding-box;
  }

  &:disabled {
    background: #b3382c 0% 0% no-repeat padding-box;
    opacity: 0.6;
  }
}

.content-table {
  padding-top: 60px;
  padding-bottom: 60px;

  .table-holder {
    overflow-y: auto;

    th {
      font-weight: 600;
      color: #000;
    }
    tr,
    td {
      color: #707070;
      font-size: 16px;
      font-weight: 400;
    }

    .table td,
    .table th {
      border-top: none !important;
    }

    td {
      border-top: none !important;
    }

    .role-button {
      border: 2px solid #e1ef18;
      font-weight: 500;
      text-align: center;
    }
  }
}

.add-form {
  padding-top: 60px;
  padding-bottom: 60px;
}

.add-question {
  padding-top: 60px;
  padding-bottom: 60px;

  .range-limits {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .range-input {
    margin-top: 16px;
    width: 100%;
  }

  .range-input-number {
    border: none;
    outline: none;
    border-bottom: 1px solid #4c4c4c;
    margin-top: 16px;
  }

  .add-more-answers {
    background-color: transparent;
    border: none;
    outline: none;
    margin-top: 32px;

    svg {
      margin-right: 12px;
    }
  }
}

.answer-section {
  .answer-field {
    position: relative;
  }

  .remove-quesiton-icon {
    position: absolute;
    right: 15px;
    top: 48px;
    cursor: pointer;
  }

  .form--control {
    padding-right: 60px;
    margin-bottom: 16px;
  }
}

.upload-form {
  .buttons {
    width: 200px;
    margin: 32px auto;

    .input-file {
      display: none;
    }

    .upload-btn {
      width: 100%;
      background: #d4831a 0% 0% no-repeat padding-box;
      box-shadow: 0px 25px 30px #0000001a;
      padding: 8px;
      border-radius: 10px;
      color: #ffffff;
      font-weight: 600;
      font-size: 18px;
      border: none;
      outline: none;
      transition: all 0.15s linear;

      &:hover {
        background: #ba751b 0% 0% no-repeat padding-box;
      }

      &:disabled {
        background: #ba751b 0% 0% no-repeat padding-box;
        opacity: 0.6;
      }
    }

    .remove-btn {
      width: 100%;
      background: #db4437 0% 0% no-repeat padding-box;
      box-shadow: 0px 25px 30px #0000001a;
      padding: 8px;
      border-radius: 10px;
      color: #ffffff;
      font-weight: 600;
      font-size: 18px;
      border: none;
      outline: none;
      transition: all 0.15s linear;

      &:hover {
        background: #cc4235 0% 0% no-repeat padding-box;
      }

      &:disabled {
        background: #cc4235 0% 0% no-repeat padding-box;
        opacity: 0.6;
      }
    }
  }

  .image-holder {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.toastify-element {
  padding: 16px;
  border-radius: 3px;
}

.navigate-button {
  margin-left: auto;
  background: #d4831a 0% 0% no-repeat padding-box;
  box-shadow: 0px 25px 30px #0000001a;
  padding: 12px;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  border: none;
  outline: none;
  transition: all 0.15s linear;
  text-decoration: none;
  display: block;
  text-decoration: none;

  &:hover {
    background: #ba751b 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
}
